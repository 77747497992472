var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "margin-top": "24px"
    }
  }, [_c('MealPlanProfileContainer', {
    attrs: {
      "is-edited": _vm.isEdited,
      "data-kondisi-giziku": _vm.dataKondisiGiziku,
      "nutritional-intake": _vm.nutritionalIntake,
      "body-conditions": _vm.bodyConditions,
      "target-and-suggestions": _vm.targetAndSuggestions,
      "is-program-consultation-basic": _vm.isProgramConsultationBasic
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }