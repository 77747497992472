<template>
  <c-box
    margin-top="24px"
  >
    <MealPlanProfileContainer
      :is-edited="isEdited"
      :data-kondisi-giziku="dataKondisiGiziku"
      :nutritional-intake="nutritionalIntake"
      :body-conditions="bodyConditions"
      :target-and-suggestions="targetAndSuggestions"
      :is-program-consultation-basic="isProgramConsultationBasic"
    />
  </c-box>
</template>

<script>
import { reqAdmin_mealPlans_preview } from '@/requests/dietela-api/admin/meal-plan'
import { CBox } from '@chakra-ui/vue'
import MealPlanProfileContainer from '@/components/meal-plan/profile/container.vue'

export default {
  components: {
    CBox,
    MealPlanProfileContainer,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataKondisiGiziku: null,
      nutritionalIntake: null,
      bodyConditions: [],
      targetAndSuggestions: null,
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.init()
      },
    },
  },
  methods: {
    async init() {
      const res = await reqAdmin_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.dataKondisiGiziku = res?.data?.data
      this.nutritionalIntake = res?.data?.data?.nutritionalIntake
      this.bodyConditions = res?.data?.data?.bodyConditions?.data ?? []
      this.targetAndSuggestions = res?.data?.data?.targetAndSuggestions
    },
  },
}
</script>
